/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable react/function-component-definition */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState, useRef} from 'react';
import LeadsearchBar from 'app/pages/leadsearch/leadsearch-bar';
import loadable from '@loadable/component';
import {useNavigate} from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Hidden from '@mui/material/Hidden';
import Box from '@mui/material/Box';
import {Helmet} from 'react-helmet-async';
import ImgWithFallback from 'app/components/webpimage';
import useMediaQuery from '@mui/material/useMediaQuery';

// Icons
import ClearIcon from '@mui/icons-material/Clear';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ScrollingLogos from '../components/scrollinglogos';

// Load non-critical components asynchronously
const VideoComponent = loadable(() => import('app/components/VideoComponent'));
const List = loadable(() => import('@mui/material/List'));
const ListItem = loadable(() => import('@mui/material/ListItem'));
const ListItemText = loadable(() => import('@mui/material/ListItemText'));

const FreeTrialField = loadable(() => import('app/pages/auth/freetrialfield'));
const HomepageSteps = loadable(() => import('app/components/homepagesteps'));
const HomepageStepsImage = loadable(() => import('app/components/homepagestepsimage'));
const Newsletter = loadable(() => import('app/layouts/newsletter'));
const CopyrightFooter = loadable(() => import('app/components/copyrightfooter'));
const ProductShowcase = loadable(() => import('app/components/productshowcase'));
const ShareWall = loadable(() => import('app/components/sharewall'));

const logoData = [
  {
    src: 'https://storage.googleapis.com/find-me-sales-bucket/front-end/homepage/homepage-logos/oracle-small.png',
    alt: 'oracle-logo',
  },
  {
    src: 'https://storage.googleapis.com/find-me-sales-bucket/front-end/homepage/homepage-logos/Google_for_Startups_logo.png',
    alt: 'google-for-startups-logo',
    // invert: true,
  },
  {
    src: 'https://storage.googleapis.com/find-me-sales-bucket/front-end/homepage/homepage-logos/barclays-eagle-labs-logo-white.png',
    alt: 'barclays-eagle-labs-logo',
  },
  {
    src: 'https://storage.googleapis.com/find-me-sales-bucket/front-end/homepage/homepage-logos/Mariellas-White.png',
    alt: 'mariellas-logo',
  },
  {
    src: 'https://storage.googleapis.com/find-me-sales-bucket/front-end/homepage/homepage-logos/UKRI-logo.png',
    alt: 'UKRI-logo',
    invert: true,
  },
  {
    src: 'https://storage.googleapis.com/find-me-sales-bucket/front-end/homepage/homepage-logos/livr-logo.png',
    alt: 'livr-logo',
    invert: true,
  },
  {
    src: 'https://storage.googleapis.com/find-me-sales-bucket/front-end/homepage/homepage-logos/digital-catapult-machine-intelligence-garage.png',
    alt: 'machine-intelligence-garage-logo',
    invert: true,
  },
  {
    src: 'https://storage.googleapis.com/find-me-sales-bucket/front-end/homepage/homepage-logos/blue-cactus-small.png',
    alt: 'blue-cactus-logo',
    invert: true,
  },
];

export default function () {
  const navigate = useNavigate();
  const isSmallScreen = useMediaQuery('(max-width:600px)');
  const helmetload = {
    title: 'Find Me Sales | Automate Sales',
    description:
      'Make sales to over 200m+ businesses worldwide with our real-time database, AI-led research, and automation to create easy sales actions.',
    url: 'https://findmesales.com',
    // image:
    //   'https://storage.googleapis.com/find-me-sales-bucket/front-end/homepage/Homepage.jpeg',
    keywords:
      'CRM, Leads, Sales, Lead generation, artificial intelligence, sales solution, AI, Automation, Sales Funnel, Sales AI',
  };

  const videoRef = useRef(null);
  const [showPlayButton, setShowPlayButton] = useState(true);
  const handlePlayClick = () => {
    if (videoRef.current) {
      const video = videoRef.current;

      // Ensure the video isn't muted.
      video.muted = false;

      // Toggle play/pause based on the video's current state.
      if (video.paused) {
        video.play();
      } else {
        video.pause();
      }

      // Update the visibility of the play button accordingly.
      setShowPlayButton(video.paused);
    }
  };
  const highlightstyle = {
    fontWeight: '700',
    color: '#003671',
  };
  return (
    <React.Fragment>
      {/* <HelmetMaker props={helmetload} /> */}
      <Helmet>
        <title>Find Me Sales | Complete Sales Solution</title>
        <meta
          name="description"
          content="Researching Companies for You, Simplifying Sales Management, and Empowering Sales Wins with Smart Outreach. Try it free now"
        />
        <meta
          name="keywords"
          content="CRM, Leads, Sales, Lead generation, artificial intelligence, sales solution, AI, Automation, Sales Funnel, Sales AI'"
        />
        <meta property="og:locale" content="en_GB" />
        <meta property="og:type" content="article" />
        <meta property="og:title" content="Find Me Sales | Complete Sales Solution" />
        <meta property="og:url" content="https://findmesales.com" />
        <meta property="og:site_name" content="Find Me Sales" />
        <meta
          property="og:image"
          content="https://storage.googleapis.com/find-me-sales-bucket/front-end/homepage/Homepage.jpg"
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@FindMeSalesHQ" />
        <meta name="twitter:title" content="Find Me Sales | Complete Sales Solution" />
        <meta
          name="twitter:description"
          content="Researching Companies for You, Simplifying Sales Management, and Empowering Sales Wins with Smart Outreach. Try it free now"
        />
        <meta
          name="twitter:image"
          content="https://storage.googleapis.com/find-me-sales-bucket/front-end/homepage/Homepage.jpg"
        />
      </Helmet>
      <CssBaseline />
      <Box
        sx={{
          display: 'flex', // Make this a flex container
          flexDirection: 'column', // Stack children vertically
          justifyContent: 'center', // Center horizontally
          position: 'relative',
          zIndex: '1000',
          marginTop: isSmallScreen ? '58px' : '96px',
          marginBottom: isSmallScreen ? '58px' : '96px',
          alignItems: 'center', // Center vertically
          width: '95%', // Set the width to 95%
          paddingLeft: '5%', // Keep your left padding
          height: '100%', // Make the Box take full height of its parent
        }}>
        <Typography
          variant="h1"
          style={{
            color: '#003671',
            minWidth: '100px',
            // paddingTop: '24px',
            fontWeight: '700',
            maxWidth: '770px',
            textAlign: 'center',
            // fontSize: '2.2rem',
            fontSize: isSmallScreen ? '24px' : '48px',
          }}>
          Sales so easy, you'll think we've given you superpowers
        </Typography>
        <Typography
          // variant="subtitle1"
          style={{
            lineHeight: '1.8',
            fontFamily: [
              'Poppins',
              'BlinkMacSystemFont',
              '"Segoe UI"',
              'Roboto',
              '"Helvetica Neue"',
              'Arial',
              'sans-serif',
            ].join(','),
            // letterSpacing: '0.07rem',
            // wordSpacing: '0.1rem',
            minWidth: '100px',
            paddingTop: '56px',
            fontWeight: '500',
            maxWidth: '954px',
            textAlign: 'center',
            fontSize: isSmallScreen ? '18px' : '24px',
          }}>
          {/* Find trusted data from Companies House and Google Maps, with the option to enrich using
          our AI, so you spend less time researching and more time selling */}
          {/* Find and Manage the Best Leads with Intelligent Insights: Explore 1 Billion People and
           250 Million Companies While Saving Time on Research */}
          {/* Save hours every week. Find and manage leads <span style={{fontWeight: '700'}}>4x faster</span> with intelligent insights. */}
          Find and manage leads <span style={highlightstyle}>4x faster</span> with intelligent
          insights,
          <br />
          powered by data from <span style={highlightstyle}>1 billion people</span> and{' '}
          <span style={highlightstyle}>250 million companies</span>
        </Typography>
      </Box>
      <Box
        sx={{
          display: 'flex', // Make this a flex container
          // flexGrow: 1,
          justifyContent: 'center', // Center horizontally
          position: 'relative',
          zIndex: '1000',
          // marginTop: '248px',
          marginBottom: '264px',
          alignItems: 'center', // Center vertically
          width: '95%', // Set the width to 90%
          paddingLeft: '5%', // Keep your left padding
          height: '100%', // Make the Box take full height of its parent
        }}>
        <LeadsearchBar />
      </Box>
      <div>
        <ProductShowcase />
      </div>
      {/* <div style={{backgroundColor: '#003671', minHeight: '20vh', overflow: 'hidden'}}> */}
      <ScrollingLogos logos={logoData} />
      {/* </div> */}
      {/* <Grid
        container
        // xs={12}
        style={{backgroundColor: '#003671', minHeight: '20vh', overflow: 'hidden'}}>
        <Grid container justifyContent="center" alignItems="center">
          <Grid item xs={6} sm={3} justifyContent="center" alignItems="center">
            <img
              src="https://storage.googleapis.com/find-me-sales-bucket/front-end/homepage/oracle-small.png"
              loading="lazy"
              alt="oracle-logo"
              style={{maxWidth: '100%', padding: '36px'}}
            />
          </Grid>
          <Grid item xs={6} sm={3} justifyContent="center" alignItems="center">
            <img
              src="https://storage.googleapis.com/find-me-sales-bucket/front-end/homepage/blue-cactus-small.png"
              loading="lazy"
              alt="blue-cactus-logo"
              style={{
                maxWidth: '100%',
                padding: '14px',
                filter: 'invert(1) brightness(1.5)',
              }}
            />
          </Grid>
          <Grid item xs={6} sm={3} justifyContent="center" alignItems="center">
            <div
              style={{
                height: '75px',
                width: '100%',
                overflow: 'hidden',
                display: 'flex',
                justifyContent: 'center',
              }}>
              <img
                src="https://storage.googleapis.com/find-me-sales-bucket/front-end/homepage/livr-logo.png"
                loading="lazy"
                alt="livr-logo"
                style={{
                  height: '93%',
                  width: 'auto',
                  maxWidth: '100%',
                  padding: '14px',
                  filter: 'invert(1) brightness(1.5)',
                }}
              />
            </div>
          </Grid>
          <Grid item xs={6} sm={3} style={{textAlign: 'center'}}>
            <img
              src="https://storage.googleapis.com/find-me-sales-bucket/front-end/homepage/skinny-logo.svg"
              loading="lazy"
              alt="skinny-bakery-logo"
              style={{maxWidth: '75%', padding: '14px'}}
            />
          </Grid>
        </Grid>
      </Grid> */}
      {/* <Grid
        container
        // xs={10}
        spacing={4}
        alignItems="center"
        justifyContent="center"
        style={{
          margin: 'auto',
          padding: '48px 5%',
          // paddingTop: '48px',
          minHeight: '90vh',
          maxWidth: '100vw',
        }}>
        <Grid container spacing={0} justifyContent="center" style={{paddingBottom: '0px'}}>
          <Typography
            variant="h3"
            align="center"
            color="primary"
            fontWeight="fontWeightBold"
            paragraph
            style={{
              maxWidth: '700px',
              paddingTop: '48px',
              paddingBottom: '24px',
              fontWeight: '600',
              // fontSize: '40px',
            }}>
            Frustrated with outdated tools and inefficient searches to find new customers?
          </Typography>
        </Grid>
        <Grid container spacing={0} justifyContent="center" style={{paddingBottom: '0px'}}>
          <Typography
            variant="body2"
            align="center"
            fontWeight="fontWeightBold"
            paragraph
            style={{
              textAlign: 'center',
              fontWeight: '500',
              maxWidth: '800px',
              paddingBottom: '24px',
            }}>
            With Find Me Sales, discover accurate, real-time business information that saves you
            time and helps you connect directly with decision-makers.
          </Typography>
        </Grid>
        <Grid container spacing={0} justifyContent="center" style={{paddingBottom: '0px'}}>
          <Typography
            variant="body2"
            align="center"
            fontWeight="fontWeightBold"
            paragraph
            style={{
              textAlign: 'center',
              fontWeight: '700',
              maxWidth: '800px',
              paddingBottom: '24px',
            }}>
            Our AI can find and research leads for you
          </Typography>
        </Grid>
        <Grid container spacing={0} justifyContent="center" style={{paddingBottom: '0px'}}>
          <FreeTrialField props={{color: 'white'}} />
        </Grid>
      </Grid> */}
      <Grid
        container
        // xs={10}
        spacing={4}
        // alignItems="center"
        justifyContent="center"
        style={{
          margin: 'auto',
          padding: '48px 5%',
          // paddingTop: '48px',
          // minHeight: '90vh',
          maxWidth: '100vw',
        }}>
        <Grid container spacing={0} justifyContent="center" style={{paddingBottom: '0px'}}>
          <Typography
            variant="h4"
            align="center"
            // color="primary"
            fontWeight="fontWeightBold"
            paragraph
            style={{
              maxWidth: '780px',
              paddingTop: '48px',
              paddingBottom: '36px',
              fontWeight: '600',
              color: 'black',
              // fontSize: '40px',
            }}>
            {/* Frustrated with outdated tools and inefficient searches to find new customers? */}
            Finding new customers
            <br />
            is the <span style={{color: '#003671'}}>hidden problem</span>
            <br /> stealing your time
          </Typography>
        </Grid>
        <Grid container spacing={0} justifyContent="center" style={{paddingBottom: '0px'}}>
          <Typography
            variant="body3"
            align="center"
            fontWeight="fontWeightBold"
            paragraph
            style={{
              textAlign: 'center',
              fontWeight: '500',
              maxWidth: '800px',
              paddingBottom: '36px',
              // fontSize: '20px',
            }}>
            We all spend hours searching for leads, yet still miss valuable prospects, chase the
            wrong opportunities, and struggle to focus.
          </Typography>
        </Grid>
        <Grid container spacing={0} justifyContent="center" style={{paddingBottom: '0px'}}>
          <Typography
            variant="body3"
            align="center"
            fontWeight="fontWeightBold"
            paragraph
            style={{
              textAlign: 'center',
              fontWeight: '500',
              maxWidth: '800px',
              paddingBottom: '36px',
              // fontSize: '20px',
            }}>
            It’s not your fault. For years, finding customers has been slow, fragmented, and manual.
            Find Me Sales makes it effortless.
          </Typography>
        </Grid>
        {/* <Grid container spacing={0} justifyContent="center" style={{paddingBottom: '0px'}}>
          <Typography
            variant="body2"
            align="center"
            fontWeight="fontWeightBold"
            paragraph
            style={{
              textAlign: 'center',
              fontWeight: '700',
              maxWidth: '800px',
              paddingBottom: '24px',
            }}>
            Our AI can find and research leads for you
          </Typography>
        </Grid> */}
        {/* <Grid container spacing={0} justifyContent="center" style={{paddingBottom: '0px'}}>
          <FreeTrialField props={{color: 'white'}} />
        </Grid> */}
      </Grid>
      <ShareWall />
      <Grid
        container
        // xs={12}
        // maxWidth="lg"
        alignItems="center"
        style={{
          // backgroundColor: '#ffffff',
          minHeight: '500px',
          padding: '10% 0px',
          margin: 'auto',
        }}>
        <Grid
          container
          display="flex"
          justifyContent="center"
          alignItems="center"
          style={{
            minHeight: '500px',
            // padding: '10% 0px',
            margin: 'auto',
            textAlign: 'center', // Ensure text alignment is centered
          }}>
          <Grid item xs={12} md={6} style={{padding: '0px 5%'}}>
            <VideoComponent
              src="/front-end/homepage/text-copy"
              style={{
                maxHeight: '500px',
                maxWidth: '100%',
                objectFit: 'contain',
                paddingBottom: '24px',
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Grid
              item
              xs={10}
              style={{
                textAlign: 'left',
                margin: 'auto',
                padding: '0px 20px 0px 20px',
                minWidth: '80%',
                maxWidth: '81%',
              }}>
              <Typography
                variant="h5"
                align="left"
                color="primary"
                paragraph
                style={{fontWeight: '600', maxWidth: '450px'}}>
                Manual research and outreach isn't the best way
              </Typography>
              <Typography
                variant="subtitle1"
                align="left"
                paragraph
                style={{
                  textAlign: 'left',
                  maxWidth: '450px',
                }}>
                When you're looking for new sales leads, it's easy to miss important data or
                understand the bigger picture.
              </Typography>
              <List
                sx={{
                  listStyleType: 'none',
                  paddingLeft: '0px',
                  // pl: 2,
                  '& .MuiListItem-root': {
                    display: 'flex',
                    alignItems: 'center',
                    backgroundColor: '#fafafa',
                  },
                  '& .MuiListItem-root.MuiListItem-gutters': {
                    backgroundColor: '#fafafa !important',
                  },
                  '& .iconBullet': {
                    marginRight: '8px',
                  },
                }}>
                <ListItem
                  id="welcome-list-1"
                  key="welcome-list-1"
                  disablePadding
                  sx={{
                    display: 'list-item',
                    backgroundColor: '#fafafa',
                  }}>
                  <ClearIcon color="secondary" />
                  <ListItemText
                    primary={
                      <Typography
                        type="body2"
                        style={{fontSize: '0.9rem', backgroundColor: '#fafafa'}}>
                        It wastes your time looking through company websites
                      </Typography>
                    }
                    style={{backgroundColor: '#fafafa', padding: '12px'}}
                  />
                </ListItem>
                <ListItem
                  disablePadding
                  id="welcome-list-2"
                  key="welcome-list-2"
                  sx={{
                    display: 'list-item',
                    backgroundColor: '#fafafa',
                  }}>
                  <ClearIcon color="secondary" />
                  <ListItemText
                    primary={
                      <Typography
                        type="body2"
                        style={{fontSize: '0.9rem', backgroundColor: '#fafafa'}}>
                        You have to copy information into your CRM yourself
                      </Typography>
                    }
                    style={{backgroundColor: '#fafafa', padding: '12px'}}
                  />
                </ListItem>
              </List>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        // xs={12}
        // maxWidth="lg"
        alignItems="center"
        style={{
          // backgroundColor: '#ffffff',
          minHeight: '500px',
          padding: '10% 0px',
          margin: 'auto',
        }}>
        <Grid item xs={12} md={6}>
          <Grid
            item
            xs={10}
            style={{
              textAlign: 'left',
              margin: 'auto',
              padding: '0px 20px 0px 20px',
              minWidth: '80%',
              maxWidth: '81%',
            }}>
            <Hidden mdUp>
              <Grid item justifyContent="center" md={6}>
                <ImgWithFallback
                  src="/front-end/homepage/competitors"
                  link="/front-end/homepage/competitors"
                  alt="competitors"
                  style={{
                    width: '100%',
                    minWidth: '100%',
                    maxWidth: '100%',
                    height: 'auto',
                    display: 'block',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    paddingBottom: '24px',
                  }}
                />
              </Grid>
            </Hidden>
            <Typography
              variant="h5"
              align="left"
              color="primary"
              paragraph
              style={{fontWeight: '600', maxWidth: '450px'}}>
              Other sales solutions don't do the work for you
            </Typography>
            <Typography
              variant="subtitle1"
              align="left"
              paragraph
              style={{
                textAlign: 'left',
                maxWidth: '450px',
              }}>
              The sales software market is a crowded place, but so many of the solutions are lacking
              in key features to help you.
            </Typography>
            <List
              sx={{
                listStyleType: 'none',
                paddingLeft: '0px',
                // pl: 2,
                '& .MuiListItem-root': {
                  display: 'flex',
                  alignItems: 'center',
                  backgroundColor: '#fafafa',
                },
                '& .MuiListItem-root.MuiListItem-gutters': {
                  backgroundColor: '#fafafa !important',
                },
                '& .iconBullet': {
                  marginRight: '8px',
                },
              }}>
              <ListItem
                id="welcome-list-1"
                key="welcome-list-1"
                disablePadding
                sx={{
                  display: 'list-item',
                  backgroundColor: '#fafafa',
                }}>
                <ClearIcon color="secondary" />
                <ListItemText
                  primary={
                    <Typography
                      type="body2"
                      style={{fontSize: '0.9rem', backgroundColor: '#fafafa'}}>
                      You have to find and research your own sales leads
                    </Typography>
                  }
                  style={{backgroundColor: '#fafafa', padding: '12px'}}
                />
              </ListItem>
              <ListItem
                disablePadding
                id="welcome-list-3"
                key="welcome-list-3"
                sx={{
                  display: 'list-item',
                  backgroundColor: '#fafafa',
                }}>
                <ClearIcon color="secondary" />
                <ListItemText
                  primary={
                    <Typography
                      type="body2"
                      style={{fontSize: '0.9rem', backgroundColor: '#fafafa'}}>
                      By using just a database or CRM you're missing out
                    </Typography>
                  }
                  style={{backgroundColor: '#fafafa', padding: '12px'}}
                />
              </ListItem>
            </List>
          </Grid>
        </Grid>
        <Hidden mdDown>
          <Grid item justifyContent="center" md={6}>
            <ImgWithFallback
              src="/front-end/homepage/competitors"
              link="/front-end/homepage/competitors"
              alt="competitors"
              style={{
                width: '100%',
                minWidth: '100%',
                maxWidth: '100%',
                height: 'auto',
                display: 'block',
                marginLeft: 'auto',
                marginRight: 'auto',
                padding: '0 10%',
              }}
            />
          </Grid>
        </Hidden>
      </Grid>
      <Grid
        container
        // xs={12}
        // maxWidth="lg"
        alignItems="center"
        style={{
          // backgroundColor: '#ffffff',
          minHeight: '500px',
          padding: '10% 0px',
          margin: 'auto',
          backgroundColor: '#003671',
        }}>
        <Grid item md={6} style={{textAlign: 'center'}}>
          <picture style={{textAlign: 'center'}}>
            <source
              srcSet="https://storage.googleapis.com/find-me-sales-bucket/front-end/homepage/flowers.webp"
              type="image/webp"
            />
            <source
              srcSet="https://storage.googleapis.com/find-me-sales-bucket/front-end/homepage/flowers.png"
              type="image/png"
            />
            <img
              src="https://storage.googleapis.com/find-me-sales-bucket/front-end/homepage/flowers.png" // This will be used as a fallback if WebP isn't supported.
              loading="lazy"
              alt="Flowers"
              style={{
                maxWidth: '60%',
                paddingBottom: '48px',
              }}
            />
          </picture>
        </Grid>
        <Grid item xs={12} md={6}>
          <Grid
            item
            xs={10}
            style={{
              textAlign: 'left',
              margin: 'auto',
              padding: '0px 20px 0px 20px',
              minWidth: '80%',
              maxWidth: '81%',
            }}>
            <Typography
              variant="h5"
              align="left"
              paragraph
              style={{
                fontWeight: '600',
                maxWidth: '450px',
                color: '#ffffff',
                paddingBottom: '14px',
              }}>
              Can you afford to let your new sales leads dry up?
            </Typography>
            <Typography
              variant="subtitle1"
              align="left"
              paragraph
              style={{
                textAlign: 'left',
                maxWidth: '450px',
                color: '#ffffff',
                paddingBottom: '14px',
              }}>
              Grow from scattered, unreliable leads to a robust, qualified prospect list using our
              automated search and CRM tools.
            </Typography>
            <Typography
              variant="subtitle1"
              align="left"
              paragraph
              style={{
                textAlign: 'left',
                maxWidth: '450px',
                color: '#ffffff',
                fontWeight: '700',
              }}>
              Start growing your sales pipeline today with our free trial - no card required!
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        // xs={12}
        // maxWidth="lg"
        alignItems="center"
        style={{
          // backgroundColor: '#ffffff',
          minHeight: '500px',
          padding: '10% 0px',
          margin: 'auto',
        }}>
        <HomepageStepsImage
          align="left"
          src="/front-end/homepage/leadsearch-add"
          step="1"
          title="Find leads in Leadsearch"
          text="Search for businesses that match your ideal customer profile using advanced filters. Pull real-time data to build a high-quality list."
          question="Is the data compliant and safe to use?"
          answer="Yes! We license the data so that it complies with all data protection laws."
        />
        <HomepageStepsImage
          align="right"
          src="/front-end/homepage/homepage-2"
          step="2"
          title="Benefit from AI research"
          text="When you enrich a sales lead in Leadsearch, our AI performs a search, then gathers people, places, context and contact information for you."
          question="Does it always find the website?"
          answer="Sometimes the business doesn't have a website, or we miss it, but you can always add it later."
        />
        <HomepageStepsImage
          align="left"
          src="/front-end/products/Leads"
          step="3"
          title="Manage leads in your CRM"
          text="Save, segment, and prioritise your prospects directly in the CRM for effective follow-ups and tracking."
          question="Can I use my existing CRM, or use my own leads?"
          answer="Yes! You can export or import leads to and from your existing CRM if you'd like."
        />
        <HomepageStepsImage
          align="right"
          src="/front-end/homepage/automation"
          step="4"
          title="Smart outreach support"
          text="Automate outreach and keep leads moving forward with simple-to-use kanban boards and workflow automation."
          question="Can this be used for spam?"
          answer="Sales Actions means there's always a human in the loop, making outreach genuine and authentic."
        />
      </Grid>
      <Grid
        container
        // xs={12}
        style={{
          backgroundColor: '#003671',
          minHeight: '50vh',
          paddingTop: '48px',
          overflow: 'hidden',
        }}>
        <Grid container justifyContent="center" alignItems="center" style={{paddingBottom: '48px'}}>
          <Grid container direction="row" alignItems="center">
            <Grid item xs={12} md={4}>
              <div
                style={{
                  margin: 'auto',
                  width: '180px',
                  height: '180px',
                  paddingTop: '6px',
                  borderRadius: '50%', // To maintain the circular shape
                  overflow: 'hidden', // To ensure the image does not overflow the circular shape
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  border: '2px solid white',
                }}>
                <picture>
                  <source
                    srcSet="https://storage.googleapis.com/find-me-sales-bucket/front-end/homepage/clare.webp"
                    type="image/webp"
                  />
                  <source
                    srcSet="https://storage.googleapis.com/find-me-sales-bucket/front-end/homepage/clare.jpg"
                    type="image/jpeg"
                  />
                  <img
                    src="https://storage.googleapis.com/find-me-sales-bucket/front-end/homepage/clare.jpg" // JPEG as a fallback
                    alt="Clare Martin"
                    style={{
                      width: '100%',
                      height: '100%',
                      objectFit: 'cover',
                    }}
                    loading="lazy"
                  />
                </picture>
              </div>
              <Typography
                // variant="subtitle1"
                onClick={() => {
                  const a = document.createElement('a');
                  a.href = 'https://www.linkedin.com/in/clare-martin-mcim/';
                  a.target = '_blank';
                  a.rel = 'noopener noreferrer';
                  a.click();
                }}
                style={{
                  color: '#fff',
                  paddingTop: '12px',
                  fontWeight: '700',
                  fontSize: '19px',
                  cursor: 'pointer',
                  textAlign: 'center', // Center the text within the grid item
                }}>
                Clare Martin
                <LinkedInIcon style={{margin: '0 0 -4px 3px'}} />
              </Typography>
              <a
                href="https://bluecactus.digital/?ref=Find-Me-Sales-homepage"
                target="_blank"
                rel="noopener noreferrer">
                <picture>
                  <source
                    srcSet="https://storage.googleapis.com/find-me-sales-bucket/front-end/homepage/blue-cactus-small.webp"
                    type="image/webp"
                  />
                  <img
                    src="https://storage.googleapis.com/find-me-sales-bucket/front-end/homepage/blue-cactus-small.png"
                    loading="lazy"
                    alt="Blue Cactus Logo"
                    style={{
                      maxWidth: '184px',
                      filter: 'invert(1) brightness(1.5)',
                      display: 'block', // Ensure the image is centered
                      margin: '0 auto', // Center the image horizontally
                    }}
                  />
                </picture>
              </a>
            </Grid>
            <Grid item xs={12} md={8}>
              <Grid container spacing={0} justifyContent="center" style={{paddingBottom: '0px'}}>
                <Typography
                  variant="h5"
                  align="center"
                  color="primary"
                  paragraph
                  style={{
                    minWidth: '100px',
                    padding: '48px 5% 24px 5%',
                    color: '#fff',
                    fontWeight: '600',
                  }}>
                  This is why our customers love Find Me Sales
                </Typography>
              </Grid>
              <Typography
                variant="subtitle1"
                align="center"
                paragraph
                style={{color: '#fff', fontSize: '1.3rem', padding: '5%'}}>
                "Find Me Sales is an essential part of our sales process at Blue Cactus Digital.
                We’ve been using it to source consultancies and tech companies to reach out to
                relevant decision makers. We have had great success finding those businesses. I
                highly recommend it for any service-based brands."
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container style={{minHeight: '50vh'}}>
        <Grid container justifyContent="center" alignItems="center" style={{padding: '48px 0px'}}>
          <Grid item xs={6} sm={3} justifyContent="center" alignItems="center">
            <img
              src="https://storage.googleapis.com/find-me-sales-bucket/front-end/homepage/oracle-small.png"
              loading="lazy"
              alt="oracle-logo"
              style={{maxWidth: '100%', padding: '36px', filter: 'invert(1)'}}
            />
          </Grid>
          <Grid item xs={6} sm={3} justifyContent="center" alignItems="center">
            <img
              src="https://storage.googleapis.com/find-me-sales-bucket/front-end/homepage/blue-cactus-small.png"
              loading="lazy"
              alt="blue-cactus-logo"
              style={{maxWidth: '100%', padding: '14px'}}
            />
          </Grid>
          <Grid
            item
            xs={6}
            sm={3}
            justifyContent="center"
            alignItems="center"
            style={{textAlign: 'center'}}>
            {/* <div
              style={{
                height: '75px',
                width: '100%',
                overflow: 'hidden',
                display: 'flex',
                justifyContent: 'center',
                textAlign: 'center',
              }}> */}
            <img
              src="https://storage.googleapis.com/find-me-sales-bucket/front-end/homepage/livr-logo.png"
              loading="lazy"
              alt="livr-logo"
              style={{
                height: '93%',
                width: 'auto',
                maxWidth: '100%',
                padding: '14px',
              }}
            />
            {/* </div> */}
          </Grid>
          <Grid
            item
            xs={6}
            sm={3}
            justifyContent="center"
            alignItems="center"
            style={{textAlign: 'center'}}>
            <img
              src="https://storage.googleapis.com/find-me-sales-bucket/front-end/homepage/skinny-logo.svg"
              loading="lazy"
              alt="skinny-bakery-logo"
              style={{maxWidth: '75%', padding: '14px', filter: 'invert(1)'}}
            />
          </Grid>
        </Grid>
        <Grid container spacing={0} justifyContent="center" style={{paddingBottom: '0px'}}>
          <Typography
            variant="h3"
            align="center"
            color="primary"
            fontWeight="fontWeightBold"
            paragraph
            style={{
              width: '700px',
              maxWidth: '90%',
              paddingTop: '24px',
              paddingBottom: '24px',
              fontWeight: '600',
              // fontSize: '40px',
            }}>
            These companies are already finding more sales,{' '}
            <span style={{textDecoration: 'underline'}}>when will you?</span>
          </Typography>
        </Grid>
        <Grid container spacing={0} justifyContent="center" style={{paddingBottom: '0px'}}>
          <Typography
            variant="body2"
            align="center"
            fontWeight="fontWeightBold"
            paragraph
            style={{
              textAlign: 'center',
              fontWeight: '500',
              width: '800px',
              maxWidth: '90%',
              paddingBottom: '24px',
            }}>
            When it comes to finding new sales, there's no such thing as having too many leads. Let
            us help you reach more businesses, so that your company continues to thrive. Start today
            with these benefits:
          </Typography>
        </Grid>
        <Grid container spacing={0} justifyContent="center" style={{paddingBottom: '0px'}}>
          <List
            sx={{
              listStyleType: 'none',
              maxWidth: '90%',
              paddingLeft: '0px',
              // pl: 2,
              '& .MuiListItem-root': {
                display: 'flex',
                alignItems: 'center',
                backgroundColor: '#fafafa',
              },
              '& .MuiListItem-root.MuiListItem-gutters': {
                backgroundColor: '#fafafa !important',
              },
              '& .iconBullet': {
                marginRight: '8px',
              },
            }}>
            <ListItem
              id="welcome-list-1"
              key="welcome-list-1"
              disablePadding
              sx={{
                display: 'list-item',
                backgroundColor: '#fafafa',
              }}>
              <CheckCircleIcon style={{color: '#08bc0c'}} />
              <ListItemText
                primary={
                  <Typography type="body2" style={{fontSize: '0.9rem', backgroundColor: '#fafafa'}}>
                    Immediately start finding relevant business leads
                  </Typography>
                }
                style={{backgroundColor: '#fafafa', padding: '12px'}}
              />
            </ListItem>
            <ListItem
              disablePadding
              id="welcome-list-4"
              key="welcome-list-4"
              sx={{
                display: 'list-item',
                backgroundColor: '#fafafa',
              }}>
              <CheckCircleIcon style={{color: '#08bc0c'}} />
              <ListItemText
                primary={
                  <Typography type="body2" style={{fontSize: '0.9rem', backgroundColor: '#fafafa'}}>
                    Cancel your subscription any time if you want to stop
                  </Typography>
                }
                style={{backgroundColor: '#fafafa', padding: '12px'}}
              />
            </ListItem>
            <ListItem
              disablePadding
              id="welcome-list-5"
              key="welcome-list-5"
              sx={{
                display: 'list-item',
                backgroundColor: '#fafafa',
              }}>
              <CheckCircleIcon style={{color: '#08bc0c'}} />
              <ListItemText
                primary={
                  <Typography type="body2" style={{fontSize: '0.9rem', backgroundColor: '#fafafa'}}>
                    Export leads that you have already found if you leave
                  </Typography>
                }
                style={{backgroundColor: '#fafafa', padding: '12px'}}
              />
            </ListItem>
          </List>
        </Grid>
        <Grid container spacing={0} justifyContent="center" style={{paddingBottom: '0px'}}>
          <Typography
            variant="body2"
            align="center"
            fontWeight="fontWeightBold"
            paragraph
            style={{
              textAlign: 'center',
              fontWeight: '700',
              maxWidth: '800px',
              paddingBottom: '24px',
              paddingTop: '16px',
            }}>
            Try out the service free forever
          </Typography>
        </Grid>
        <Grid container spacing={0} justifyContent="center" style={{paddingBottom: '0px'}}>
          <FreeTrialField props={{color: 'white'}} />
        </Grid>
      </Grid>
      {/* <a className="wavesanchor" id="Waves" /> */}
      <Grid item xs={12} style={{paddingBottom: '-10px', maxWidth: '100%', overflow: 'hidden'}}>
        <Grid
          container
          spacing={0}
          justifyContent="center"
          style={{paddingBottom: '0px', maxWidth: '100%'}}>
          <Typography
            variant="h5"
            align="center"
            color="primary"
            fontWeight="fontWeightBold"
            paragraph
            style={{
              minWidth: '100px',
              paddingTop: '20%',
              // paddingBottom: '12px',
              marginBottom: '0px',
              fontWeight: '600',
            }}>
            Accelerators we've completed
          </Typography>
        </Grid>
        <Grid
          container
          // xs={12}
          spacing={2}
          alignItems="center"
          style={{
            margin: 'auto',
            paddingBottom: '48px',
            // paddingTop: '48px',
            paddingLeft: '0px',
            verticalAlign: 'center',
            minHeight: '400px',
            maxWidth: '100%',
          }}>
          <Hidden mdDown>
            <Grid
              item
              xs={12}
              sm={6}
              md={2}
              onClick={() => {
                navigate('insights/Find-Me-Sales-joins-Google-Cloud-Startup-Program');
              }}>
              <Grid item xs={10} style={{textAlign: 'center', margin: 'auto'}}>
                <div style={{fontSize: '100px', textAlign: 'center'}}>
                  <ImgWithFallback
                    src="/assets/google-cloud-for-startups"
                    link="/assets/google-cloud-for-startups"
                    alt="home-header"
                    style={{
                      width: '100%',
                      minWidth: '100%',
                      cursor: 'pointer',
                      minHeight: '100%',
                      maxWidth: '100%',
                      height: 'auto',
                      display: 'block',
                      marginLeft: 'auto',
                      marginRight: 'auto',
                      padding: '0% 0% 5% 10%',
                    }}
                  />
                </div>
              </Grid>
            </Grid>
          </Hidden>
          <Hidden mdUp>
            <Grid
              item
              xs={12}
              sm={6}
              md={2}
              onClick={() => {
                navigate('insights/Find-Me-Sales-joins-Google-Cloud-Startup-Program');
              }}>
              <Grid item xs={10} style={{textAlign: 'center', margin: 'auto'}}>
                <div style={{fontSize: '100px', textAlign: 'center'}}>
                  <ImgWithFallback
                    src="/assets/google-cloud-for-startups"
                    link="/assets/google-cloud-for-startups"
                    alt="Google-For-Startups"
                    style={{
                      width: '100%',
                      minWidth: '100%',
                      cursor: 'pointer',
                      minHeight: '100%',
                      maxWidth: '100%',
                      height: 'auto',
                      display: 'block',
                      marginLeft: 'auto',
                      marginRight: 'auto',
                      // paddingTop: '18%',
                    }}
                  />
                </div>
              </Grid>
            </Grid>
          </Hidden>
          <Hidden mdDown>
            <Grid
              item
              xs={12}
              sm={6}
              md={3}
              onClick={() => {
                navigate('/insights/Innovate-UK-Edge-Program-Accepts-Find-Me-Sales');
              }}>
              <Grid item xs={10} style={{textAlign: 'center', margin: 'auto'}}>
                <div style={{fontSize: '100px', textAlign: 'center'}}>
                  <ImgWithFallback
                    src="/assets/innovateuk-edge"
                    link="/assets/innovateuk-edge"
                    alt="home-header"
                    style={{
                      width: '100%',
                      minWidth: '100%',
                      minHeight: '100%',
                      cursor: 'pointer',
                      maxWidth: '100%',
                      height: 'auto',
                      display: 'block',
                      marginLeft: 'auto',
                      marginRight: 'auto',
                      padding: '0% 15% 5% 15%',
                    }}
                  />
                </div>
              </Grid>
            </Grid>
          </Hidden>
          <Hidden mdUp>
            <Grid
              item
              xs={12}
              sm={6}
              md={3}
              onClick={() => {
                navigate('/insights/Innovate-UK-Edge-Program-Accepts-Find-Me-Sales');
              }}>
              <Grid item xs={10} style={{textAlign: 'center', margin: 'auto'}}>
                <div style={{fontSize: '100px', textAlign: 'center'}}>
                  <ImgWithFallback
                    src="/assets/innovateuk-edge"
                    link="/assets/innovateuk-edge"
                    alt="home-header"
                    style={{
                      width: '100%',
                      minWidth: '100%',
                      minHeight: '100%',
                      cursor: 'pointer',
                      maxWidth: '100%',
                      height: 'auto',
                      display: 'block',
                      marginLeft: 'auto',
                      marginRight: 'auto',
                      paddingBottom: '20px',
                    }}
                  />
                </div>
              </Grid>
            </Grid>
          </Hidden>
          <Hidden mdUp>
            <Grid
              item
              xs={12}
              sm={6}
              md={3}
              onClick={() => {
                navigate('/insights/natwest-entrepreneur-accelerator-welcomes-find-me-sales');
              }}>
              <Grid item xs={12} style={{textAlign: 'center', margin: 'auto'}}>
                <div style={{fontSize: '100px', textAlign: 'center'}}>
                  <ImgWithFallback
                    src="/assets/natwest-accelerator"
                    link="/assets/natwest-accelerator"
                    alt="home-header"
                    style={{
                      width: '100%',
                      minHeight: '100%',
                      maxWidth: '400px',
                      height: 'auto',
                      cursor: 'pointer',
                      display: 'block',
                      paddingLeft: '8%',
                      paddingRight: '8%',
                      marginLeft: 'auto',
                      marginRight: 'auto',
                      paddingBottom: '20px',
                      // paddingTop: '18%',
                    }}
                  />
                </div>
              </Grid>
            </Grid>
          </Hidden>
          <Hidden mdDown>
            <Grid
              item
              xs={12}
              sm={6}
              md={2}
              onClick={() => {
                navigate('/insights/natwest-entrepreneur-accelerator-welcomes-find-me-sales');
              }}>
              <Grid item xs={12} style={{textAlign: 'center', margin: 'auto'}}>
                <div style={{fontSize: '100px', textAlign: 'center'}}>
                  <ImgWithFallback
                    src="/assets/natwest-accelerator"
                    link="/assets/natwest-accelerator"
                    alt="home-header"
                    style={{
                      width: '100%',
                      minWidth: '100%',
                      minHeight: '100%',
                      maxWidth: '400px',
                      height: 'auto',
                      display: 'block',
                      cursor: 'pointer',
                      marginLeft: 'auto',
                      marginRight: 'auto',
                      paddingBottom: '24px',
                    }}
                  />
                </div>
              </Grid>
            </Grid>
          </Hidden>
          <Hidden mdUp>
            <Grid
              item
              xs={12}
              sm={6}
              md={3}
              onClick={() => {
                navigate('/insights/Find-Me-Sales-starts-Barclays-Eagle-Labs');
              }}>
              <Grid item xs={12} style={{textAlign: 'center', margin: 'auto'}}>
                <div style={{fontSize: '100px', textAlign: 'center'}}>
                  <ImgWithFallback
                    src="/assets/Barclays-Eagle-Labs"
                    link="/assets/Barclays-Eagle-Labs"
                    alt="home-header"
                    style={{
                      width: '100%',
                      minHeight: '100%',
                      maxWidth: '400px',
                      height: 'auto',
                      cursor: 'pointer',
                      display: 'block',
                      marginLeft: 'auto',
                      marginRight: 'auto',
                      padding: '0px 6%',
                      // paddingTop: '18%',
                    }}
                  />
                </div>
              </Grid>
            </Grid>
          </Hidden>
          <Hidden mdDown>
            <Grid
              item
              xs={12}
              sm={6}
              md={3}
              onClick={() => {
                navigate('/insights/Find-Me-Sales-starts-Barclays-Eagle-Labs');
              }}>
              <Grid item xs={12} style={{textAlign: 'center', margin: 'auto'}}>
                <div style={{fontSize: '100px', textAlign: 'center'}}>
                  <ImgWithFallback
                    src="/assets/Barclays-Eagle-Labs"
                    link="/assets/Barclays-Eagle-Labs"
                    alt="home-header"
                    style={{
                      width: '100%',
                      minWidth: '100%',
                      minHeight: '100%',
                      maxWidth: '400px',
                      height: 'auto',
                      display: 'block',
                      cursor: 'pointer',
                      marginLeft: 'auto',
                      marginRight: 'auto',
                      // width: '50%',
                      // paddingTop: '35%',
                      padding: '0% 20% 5% 10%',
                    }}
                  />
                </div>
              </Grid>
            </Grid>
          </Hidden>
          <Hidden mdUp>
            <Hidden xsDown>
              <Grid item xs={3} />
            </Hidden>
            <Grid
              item
              xs={12}
              sm={6}
              md={3}
              onClick={() => {
                navigate(
                  '/insights/Find-Me-Sales-accepted-onto-Digital-Catapult-Machine-Intelligence-Garage'
                );
              }}>
              <Grid item xs={12} style={{textAlign: 'center', margin: 'auto'}}>
                <div style={{fontSize: '100px', textAlign: 'center'}}>
                  <ImgWithFallback
                    src="/assets/digital-catapult-machine-intelligence-garage"
                    link="/assets/digital-catapult-machine-intelligence-garage"
                    alt="home-header"
                    style={{
                      width: '100%',
                      // minWidth: '100%',
                      minHeight: '100%',
                      maxWidth: '400px',
                      height: 'auto',
                      cursor: 'pointer',
                      display: 'block',
                      marginLeft: 'auto',
                      marginRight: 'auto',
                      // width: '50%',
                      // paddingTop: '35%',
                      padding: '0% 10% 5% 10%',
                    }}
                  />
                </div>
              </Grid>
            </Grid>
          </Hidden>
          <Hidden mdDown>
            <Grid
              item
              xs={12}
              sm={6}
              md={2}
              onClick={() => {
                navigate(
                  '/insights/Find-Me-Sales-accepted-onto-Digital-Catapult-Machine-Intelligence-Garage'
                );
              }}>
              <Grid item xs={12} style={{textAlign: 'center', margin: 'auto'}}>
                <div style={{fontSize: '100px', textAlign: 'center'}}>
                  <ImgWithFallback
                    src="/assets/digital-catapult-machine-intelligence-garage"
                    link="/assets/digital-catapult-machine-intelligence-garage"
                    alt="home-header"
                    style={{
                      width: '100%',
                      minWidth: '100%',
                      minHeight: '100%',
                      maxWidth: '400px',
                      height: 'auto',
                      display: 'block',
                      cursor: 'pointer',
                      marginLeft: 'auto',
                      marginRight: 'auto',
                      // width: '50%',
                      // paddingTop: '35%',
                      padding: '0% 10% 5% 0%',
                    }}
                  />
                </div>
              </Grid>
            </Grid>
          </Hidden>
        </Grid>
      </Grid>
      <Grid container style={{backgroundColor: '#003671', minHeight: '10vh'}} />
      <Newsletter />
      <CopyrightFooter />
    </React.Fragment>
  );
}
